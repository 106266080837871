<template>
  <div class="common-no-header-dialog project-mgr">
    <schedule v-if="showSchedule" :project-id="selectProjectId" @close="onClose"></schedule>
    <iframe name="executeChannel" style="display: none" :src="executeChannel"></iframe>
  </div>
</template>
<script>
import schedule from "../index";

export default {
  name: "ScheduleMgr",
  components: { schedule },
  data() {
    return {
      editFields: {},
      readFields: {},
      inputDisabled: true,
      fileModules: [],
      selectProjectId: 0,
      deptUserList: [],
      projectTable: [],
      contentTxt: "项目信息",
      executeChannel: "",
      showSchedule: false,
    };
  },
  computed: {},
  mounted() {
    const that = this;
    window.frameCallBack = that.frameCallBack;
    document.getElementsByTagName("body")[0].className = "projectMgr";
    this.onOpen();
  },
  methods: {
    onClose(processData) {
      // 发送关闭消息
      // this.executeChannel =
      //   "http://localhost:8087/lead_biz_project_mgr/executeChannel?str=closePro&t=" + Math.random();
      const params = { processData: processData };
      const message = {
        type: "returnToMgr",
        params: params,
      };
      window.parent.postMessage(message, "*");
    },
    getQueryString(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      const r = window.location.search.substr(1).match(reg);
      if (r !== null) return unescape(r[2]);
      return "";
    },
    onOpen() {
      // this.projectTable = this.getDHubFields(this.GLOBAL.POLICY_KEYS.projectTable);
      this.selectProjectId = parseInt(this.getQueryString("projectId"));
      const that = this;
      setTimeout(function () {
        that.showSchedule = true;
      }, 100);
    },
    setTitle(title) {
      this.contentTxt = title;
    },
    getProject() {
      this.$emit("getProject", 1);
    },
    websocketSend(param) {
      this.$emit("websocketSend", param);
    },
    closeProjectDetail() {
      // this.$refs.projectDetail.closeProjectDetail();
    },
  },
};
</script>

<style lang="scss">
.projectMgr .el-dialog__wrapper {
  top: 0 !important;
  overflow: hidden;
}

.projectMgr .v-modal {
  top: 0 !important;
}

/*没有头尾的dialog*/
.common-no-header-dialog {
  .el-dialog__header,
  .el-dialog__footer {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    font-size: 12px;
  }

  .el-main {
    padding: 10px;
  }
}
.common-bottom-border {
  border-bottom: 1px solid #dfe6ec;
}

.plr10 {
  padding: 0 10px;
}
</style>
